import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`How to Manage Your Investments`}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/find-your-portfolio/"
          }}>{`How to choose a portfolio`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/account-rebalancing/"
          }}>{`How to rebalance your account`}</a></li>
      </ul>
    </Aside>
    <p><em parentName="p">{`TuringTrader`}</em>{`'s dashboard is the central place to manage your investments. Here, you can see your investment accounts, what their current values are, and which of them need your attention. Further, we display indicators for the current market regime, and the most important drivers of asset prices.`}</p>
    <h2>{`Create Investment Accounts`}</h2>
    <p>{`The first step in managing your investments with `}<em parentName="p">{`TuringTrader`}</em>{` is to find your portfolios and create investment accounts for them on your dashboard. We have written a detailed article on `}<a parentName="p" {...{
        "href": "/help/find-your-portfolio"
      }}>{`how to find a portfolio that suits your needs`}</a>{`.`}</p>
    <div css={`display:flex;align-items:center;column-gap:1em;`}>
      <div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "224px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0aefbd135b2e6515e4b06b8ea57462f6/80b2d/new-portfolio-0.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "21.875%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABEUlEQVQY0z3KvU7CYACF4U7EjUFdiNHiH1EjkYaQEIJWSkBUEBs3Bjc3pUgLek0OXoGOtPB92I60xM3BeBOvSTEOJ+ec5FFef15ozIq0wlOuokVuonr822EFM6rSjHSuwgrt0KAZnsTmOjT+fZyZwfvXG8rt3CTjqXTEJTW3TMuto3kqZ6JIWeYouUeYnk5tUsQQGqZXpzXSaYyPuRjpVF2N87FO2lui7z+g3M07rE4SlD4OyMoN8mKXlEyQna6x76dYF0nyIo023WRLJinIDLmJyo5c5lCqFMQ2BbnHilAYShvF+3ax/XvsoIsTWHE/Bw5O0GPg93gK+tiBheNbDOO9cMPg8c9bOEGXwbTP+FPwC1WNwecHTK52AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "new portfolio 0",
                "title": "new portfolio 0",
                "src": "/static/0aefbd135b2e6515e4b06b8ea57462f6/80b2d/new-portfolio-0.png",
                "srcSet": ["/static/0aefbd135b2e6515e4b06b8ea57462f6/80b2d/new-portfolio-0.png 224w"],
                "sizes": "(max-width: 224px) 100vw, 224px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "652px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/56cd8e449a1eb2053c481e5c83dd9079/dba9a/new-portfolio-1.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "45.666666666666664%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABiklEQVQoz6WQy27TQBSG/Wa8Aw/TNRs2rNmwqJC4LEAqatlRVVyKaKME2twdJ1HIrU4cx/Z4PHacOOpXzbQVjVghjvTp/HMu/4zGen1U5vBLjw8nHQ5OOnz86vDpR5+L1oSL9oSqPaXWmVJ3rmh2r2j1XNp9F7vv4gxmdH/PcQYuYzfgzcFnrKYzJstSRBwbZJIQS2lIlCJRCUoplEqQiTRnKSUqvavJmDRN0XFWaWDV7CEilnh+yDIUBKEgjIQxjERMFMdEQtxqoXuR0bqv54IoMvs6vp1dYlUaIwZuQmcY0ZsIuiPBdKHI1wXFdsumKHZ4WNNak6/XxvD0vIpV1y8UIf5yaVj4vkHrueexDAKjvcUCPwhMvp/RPZ1nc49iu+G0VMWqtgeIOMS7GwrCW60XtTaLJmtTf+fSe9OZ5wHXfC/VsBrtEZnIyNWGVb4mW+Ws8l2y/O/aQ9Is+/OHr+x3PB4+4sl4j/+N8qWN9eLwPU+Pn/H8eJ/yrxaln41/5rxSp9bs8vLtETfXeZl/iGUDhAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "new portfolio 1",
                "title": "new portfolio 1",
                "src": "/static/56cd8e449a1eb2053c481e5c83dd9079/dba9a/new-portfolio-1.png",
                "srcSet": ["/static/56cd8e449a1eb2053c481e5c83dd9079/5a46d/new-portfolio-1.png 300w", "/static/56cd8e449a1eb2053c481e5c83dd9079/0a47e/new-portfolio-1.png 600w", "/static/56cd8e449a1eb2053c481e5c83dd9079/dba9a/new-portfolio-1.png 652w"],
                "sizes": "(max-width: 652px) 100vw, 652px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "648px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3bc8ab27c4049e27937cea1970c1abc2/3996e/new-portfolio-2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "41.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABbklEQVQoz5WRTW/TQBCG/VP5CRwQ/wQJqQdugJDaHugJhQsI0UqQtkASh9hJjLMhsb32xrv+kBP3QV5DKyGQYKVnZ96ZObw76zw9G3L62uVkMOZ4MOZkMOHVuxnDUcBw9I3LccjVJOSTG/J5umI0E4w9wcQTuL7g63zN1BcEIuL52QWOF2woywK1y9nlmtwYtDEUZYE2mlxrTFGgtba6i8Z0tbuZoizpzocvIY47X5PIDJlmZEqhdjvSLGMbx8RS2jxVyva6PJEpMk1vdT+jgBveX85xrqff8VY5cVb1qIqtNESJpK5rDm1Ls9+zPxz+St001uH51QLH9VdkSlpHURxbB0nau/hFnCS3MYoTEimts053+WYb0TQV59cLHN+PKLISnRZ2F2VVU1bVb/ypdlc3RbfDtn/yM++Y+8t7PFk/trZvaO39P7Rt23/KKMQ5evOCB28f8ujiiCCI8ZYCb7n+J/yfzBYCsUl4OfjID+PsVIN+amgtAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "new portfolio 2",
                "title": "new portfolio 2",
                "src": "/static/3bc8ab27c4049e27937cea1970c1abc2/3996e/new-portfolio-2.png",
                "srcSet": ["/static/3bc8ab27c4049e27937cea1970c1abc2/5a46d/new-portfolio-2.png 300w", "/static/3bc8ab27c4049e27937cea1970c1abc2/0a47e/new-portfolio-2.png 600w", "/static/3bc8ab27c4049e27937cea1970c1abc2/3996e/new-portfolio-2.png 648w"],
                "sizes": "(max-width: 648px) 100vw, 648px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <p>{`Once you found a portfolio you want to track, clicking the button labelled `}<inlineCode parentName="p">{`Invest In This Portfolio`}</inlineCode>{`. A wizard will show up, asking you to enter a descriptive name for your investment account. You can use any name you like here, as long as it is useful to you to recognize your account. Next, the wizard will ask you to enter the total value of your investment account. This amount should match the dollar value of your account, so that the number of shares calculated will be correct.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "956px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6e3bfdd2b98e3f02027b231e350090b3/7b1dc/new-portfolio-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.333333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAqUlEQVQI1z2PMW7DMBAE9f9npMoTgpQpU7gJ4N4wTCqSSIkieXdUbExgBkgx2L3DNDuELbOX2qlqHVGjiOGnjVwE0UYV4zaufIfcndbunMYv3q7vxLqheiBmDM57xmlmzxm1hpr958055iVgrXU5rhFR7f1+PPi4fPJyfmUpC639oM0Y5rgTU2XdhfgkyV9Plaub8NPa7ydhK/3fnSw4L4zuIGUjpNpX/QJ58ORUA6wGBgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "new portfolio 3",
            "title": "new portfolio 3",
            "src": "/static/6e3bfdd2b98e3f02027b231e350090b3/7b1dc/new-portfolio-3.png",
            "srcSet": ["/static/6e3bfdd2b98e3f02027b231e350090b3/5a46d/new-portfolio-3.png 300w", "/static/6e3bfdd2b98e3f02027b231e350090b3/0a47e/new-portfolio-3.png 600w", "/static/6e3bfdd2b98e3f02027b231e350090b3/7b1dc/new-portfolio-3.png 956w"],
            "sizes": "(max-width: 956px) 100vw, 956px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you completed the wizard, `}<em parentName="p">{`TuringTrader`}</em>{` will create the investment account for you, and place it on your dashboard. Most investors have more than one brokerage account, typically one for their 401(k) or IRA, possibly one for their spouse, and one for their taxable investments. Consequently, you can repeat the steps outlined above, and add as many accounts as you like to your dashboad.`}</p>
    <h2>{`Check on Your Accounts`}</h2>
    <p>{`All the information on the `}<em parentName="p">{`TuringTrader`}</em>{` site is updated daily. Specifically, we update the following information about your accounts:`}</p>
    <ul>
      <li parentName="ul">{`the account value`}</li>
      <li parentName="ul">{`the target allocation`}</li>
      <li parentName="ul">{`the need for rebalancing`}</li>
    </ul>
    <p>{`With this information maintained on our site, there should be no need to log into your brokerage account, unless you need to rebalance one or more accounts. In the example above, you can see that one account requires rebalancing, while the other account is current.`}</p>
    <p>{`Most `}<em parentName="p">{`TuringTrader`}</em>{`'s portfolios follow a pre-defined rebalancing schedule. We have portfolios that rebalance their target allocation monthly, weekly, and daily. However, it is important to notice that even portfolios that rebalance on a daily schedule do not necessarily require you to place any orders every day. Instead, you will find that many days there is nothing to do.`}</p>
    <p>{`Your investment results only match our simulations closely, if you follow our target allocations accurately. But you don't have to check our site daily to make sure that you don't miss an important asset rotation. Instead, we can send you a notification email when your investments require your attention.`}</p>
    <div css={`display:flex;align-items:center;column-gap:1em;`}>
      <div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "549px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/074701432dd1a1362f9e508de2b45435/928ea/enable-notifications.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.000000000000004%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABN0lEQVQY042PyS5DARhG74t4Bq/hHTyCvRDDSkQqxEyr1FiJaC+JHQukCFql1ViY7nVnlfYO1dbUI70kTcTCl5x8J9/q/4XeoXXa+1foDkTpCkTpHFilZzDK8MwGI6EYo7MxxsJxxsNxJubiTM6LTEVEpiMiwYVNQktbBJdFwqvbdPSFEOT8KznZ5UYvI1tVbvUKd3qF6jt88M0n/8t+SkZ488q4eQf7ucBT3sQ0NCzLwHqyUDUVwzR9dMPwUTUdRVV5VFUUVaNo26xpS0jOHUdJCSHjnLOr7XCVz1HyyhQdF9v1cL0Szk/77tXd87u+17Edl9JLmUv7gkL1mYPTe4RWqYXmZBPtt23+2TX/ydoP/OG/09gO0w8IYnKf4N4G4nGCVFbhNCNx9h+yDU4yD6SvFRZjCb4ACA6o/sYiur0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "enable notifications",
                "title": "enable notifications",
                "src": "/static/074701432dd1a1362f9e508de2b45435/928ea/enable-notifications.png",
                "srcSet": ["/static/074701432dd1a1362f9e508de2b45435/5a46d/enable-notifications.png 300w", "/static/074701432dd1a1362f9e508de2b45435/928ea/enable-notifications.png 549w"],
                "sizes": "(max-width: 549px) 100vw, 549px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "629px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/aafef3ecadf9cbc10073333baf725aa7/63a68/rebalance-notification.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABLklEQVQ4y51SS07EMAztiZG4AAdBbGHLARCwmpEQMy07hIZ2UIfh28T95tMmfSiRWkCCRWvpyY6d2M92gk+qIKWEtRZKabRti67r0Pf9LARn5xeIohBhdIfLq2us1iEWiyWMMZgjQV1VUEpBCIGyLD1bZzuWU+G6DByTwTHYjroLToVvmYjAMoaMMTDGwRjzgbkSGGt8dsdu0G4x/0Fr7TH6tB7n7RkmcYJdmiKOE2y3T+CcoyhKFDkhz3NwIhBxEHeafAcOw9ndl1J9J5RKQmoJoaRfzlwZxhQcpic42p96HKTHWNUbHzC9nfT/RoY3zQPWIsZtvcGyugfryl8VJy+FXjP0wgDD+x6wPypOTsiIgxOHse2fM5mc8OP9DYxKJC8Cyb7B43MDqtrZDL8Adi/2oawBIqsAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rebalance notification",
                "title": "rebalance notification",
                "src": "/static/aafef3ecadf9cbc10073333baf725aa7/63a68/rebalance-notification.png",
                "srcSet": ["/static/aafef3ecadf9cbc10073333baf725aa7/5a46d/rebalance-notification.png 300w", "/static/aafef3ecadf9cbc10073333baf725aa7/0a47e/rebalance-notification.png 600w", "/static/aafef3ecadf9cbc10073333baf725aa7/63a68/rebalance-notification.png 629w"],
                "sizes": "(max-width: 629px) 100vw, 629px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <p>{`To enable email notifications, click the little cog wheel at the top of the dashboard page. Then, click on the little toggle switch. You will now receive a single email, whenever one or more accounts need rebalancing. The notification emails are intentionally sparse. They do not contain any information which accounts need rebalancing, and the also do not include any information which assets are rotated in or out. However, they include a bright green button that will take you to straight to the dashboard.`}</p>
    <p>{`The rebalancing process is simple and`}{`—`}{`once you've done it a couple times`}{`—`}{`should only take you a few minutes. We have written a separate `}<a parentName="p" {...{
        "href": "/help/account-rebalancing/"
      }}>{`article explaining the rebalancing procedure`}</a>{`.`}</p>
    <h2>{`Check the Investment Climate`}</h2>
    <p>{`Of course your accounts are the most imporant information on the dashboard. But investing does not happen in a vaccuum. Instead, investments are linked to the developments in the various market places and the economy. Therefore, prudent investors watch the market environment closely.`}</p>
    <div css={`display:flex;align-items:center;column-gap:1em;`}>
      <div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "961px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b8ffab24dc77852fd7f4467235567130/024d6/market-vane.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAyElEQVQY02WPy2rDMBBF/ev5p5JNKHTRRba1S+jDhPolN42J7SixNZLpKZJjWujiwjzPzI3EWoJEMNM38rVH6tc5Fpm19K3DXjp0FpOXJSfd0l47tGhMmLVEfjgkZsQANrnDrVch9rUAWuSB156ueOEtTUnqmOQQ0w4tCycytw/mBf+JQfojxk030B8HbsLqhiHd8vSccJ9teDw9kA0f/4HHpiHLC6q6Jq8UlVIUZcVozO9BNyHnhvGwD5Z3nzvS/h01qmDXA38AyOYw0qYt8dgAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "market vane",
                "title": "market vane",
                "src": "/static/b8ffab24dc77852fd7f4467235567130/024d6/market-vane.png",
                "srcSet": ["/static/b8ffab24dc77852fd7f4467235567130/5a46d/market-vane.png 300w", "/static/b8ffab24dc77852fd7f4467235567130/0a47e/market-vane.png 600w", "/static/b8ffab24dc77852fd7f4467235567130/024d6/market-vane.png 961w"],
                "sizes": "(max-width: 961px) 100vw, 961px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "977px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e63d5350eaf3c5f1d070b384defd9b1d/339e3/indicators.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "15%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAArUlEQVQI1zWMy26DMAAE+f+PSm895dqWpERqFIGNE4ONCX4BE0GV2+5odouLChxKx3fjYV2o+8iP8IxhBpadwcrx78lZ+n8GSJuoWo+dMttHpQLCRIpunGmHmY/TewA+w1cTESZxd4lSRmqTcWFzE78qcW4jU5yJeSHklc/LREgLRecCShukdkgT0ENA3A3X1lJ3nlp7yluPfFjsM9L0nqrZ/GHv/RgR2nFTw55fdSfkT62v+pYAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "indicators",
                "title": "indicators",
                "src": "/static/e63d5350eaf3c5f1d070b384defd9b1d/339e3/indicators.png",
                "srcSet": ["/static/e63d5350eaf3c5f1d070b384defd9b1d/5a46d/indicators.png 300w", "/static/e63d5350eaf3c5f1d070b384defd9b1d/0a47e/indicators.png 600w", "/static/e63d5350eaf3c5f1d070b384defd9b1d/339e3/indicators.png 977w"],
                "sizes": "(max-width: 977px) 100vw, 977px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <p><em parentName="p">{`TuringTrader`}</em>{` makes it easy to follow the markets, right from the dashboard. For once, we offer our `}<em parentName="p">{`Market Vane`}</em>{`, which is a sophisticated indicator to signal the current market regime. Based on historical prices and macro-economic indicators, `}<em parentName="p">{`Market Vane`}</em>{` provides guidance on the basic risk-on vs risk-off investment decision. We recommend reading our article explaining `}<a parentName="p" {...{
        "href": "/articles/market-vane/"
      }}>{`how Market Vane works`}</a>{`.`}</p>
    <p>{`Further, the dashboard shows miniature charts of various markets, including stocks, bonds, commodities, and forex. This charts provide high-level information of the current market trends and conditions. We recommend checking our `}<a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}>{`indicator page`}</a>{` to learn more.`}</p>
    <ButtonPrimary text="open dashboard" to="/dashboard/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      